import React from "react";
import { Link } from "gatsby";
import Logo from "./Logo";
import Footer from "./Footer";
import StyleSheet from "./StyleSheet";
import SEO from "./SEO";
import RichText from "./RichText";
import styled from "styled-components";
import { debounce, map, sortBy } from "lodash";
import { ButtonExternalLink } from "./Button";

const useMediaQuery = () => {
  const [isMobile, setIsMobile] = React.useState(false);

  React.useLayoutEffect(() => {
    if (typeof window === "undefined") return undefined;
    function checkDimensions() {
      setIsMobile(window.innerWidth < 769);
    }

    window.addEventListener("resize", checkDimensions);
    checkDimensions();

    return () => {
      window.removeEventListener("resize", checkDimensions);
    };
  }, []);

  return isMobile;
};

const Header = styled.header`
  align-items: center;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  height: 95px;
  padding: 0 5rem;
  border-bottom: 1px solid #cfd8dd;
`;

const Main = styled.main`
  padding: 1rem 3rem 3rem 6rem;

  section {
    margin-bottom: 4rem;
    margin-top: 3rem;
  }

  h4 {
    font-size: 1rem;
    margin-top: 0 !important;
    margin-bottom: 1rem;
  }

  ul {
    margin-top: 0;
  }

  h2 {
    font-size: 1.266em;
  }

  h3 {
    font-size: 1.131em;
  }

  p {
    max-width: 650px;
  }
`;

const Overflow = styled.div`
  overflow: hidden;
  width: 100%;
`;

const Wrapper = styled.div`
  max-width: 100vw;
  min-width: 100vw;
  transition: margin 250ms;
  margin-left: ${({ open }) => (open ? "210px" : "0")};
`;

const TableOfContents = styled.nav`
  background-color: #e6f2fa90;
  box-sizing: border-box;
  border-right: 1px solid #cfd8dd;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
  bottom: 0;
  top: 0;
  overflow: auto;
  margin-left: ${({ open }) => (open ? 0 : "-210px")};
  padding: 2rem 0 8rem 2rem;
  position: fixed;
  width: 210px;
  transition: margin 250ms;
  z-index: 100;
  overscroll-behavior: contain;

  h3 {
    font-size: 1rem;
  }

  ul {
    list-style: none;
    margin: 1rem 0;
    padding: 0;
  }

  li {
    padding: 0;
    margin: 0;
  }

  a {
    color: #55656d;
    padding-left: 0;
    padding: 0.5rem;
    font-size: 1em;
    text-decoration: none;
    display: block;
    will-change: color;
    transition: color 350ms;
    font-weight: normal;
  }

  .focus {
    color: #123e6f;
    font-weight: bold;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #e6f2fa90;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #55656d;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #55656d;
  }
`;

const Button = styled.button`
  background: transparent;
  border: 0;
  color: #123e6f;
  position: fixed;
  top: 17vh;
  cursor: pointer;
  left: 2rem;
  transition: color, transform 200ms;
  transform: ${({ open }) => (open ? "translateX(210px)" : "translate(0)")};

  svg {
    fill: #123e6f;
    transition: fill 200ms;
  }

  :hover svg {
    fill: #55656d;
  }
`;

const Version = styled.h2`
  color: #123e6f;
  margin: 0;
`;

const Date = styled.em`
  color: #55656d;
  margin: 0 0 1rem 0;
`;

const DownloadButtonDiv = styled.div`
  left: 7rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const Section = styled.section`
  position: relative;
`;

const Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <path d="M15 21v-10l9 5-9 5zm-3 0h-12v-2h12v2zm0-4.024h-12v-2h12v2zm0-3.976h-12v-2h12v2zm12-4h-24v-2h24v2zm0-6v2h-24v-2h24z" />
  </svg>
);

export default function TableOfContentsComponent({
  children = null,
  entries: rawEntries,
  name,
  target,
}) {
  const container = React.useRef();
  const matches = !useMediaQuery();
  const [open, setOpen] = React.useState(matches);
  const entries = sortBy(
    map(rawEntries, (entr) => {
      return {
        acc: String(entr.version)
          .split(".")
          .map((part) => {
            return "00000000".substr(0, 8 - part.length) + part;
          }),
        ...entr,
      };
    }),
    target === "version" ? "acc" : target
  ).reverse();

  const toggle = () => setOpen(!open);

  const handleLink = (id) => (e) => {
    try {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }

      window.scroll({
        top: document.getElementById(id).offsetTop,
        behavior: "smooth",
      });
    } catch (e) {
      // noop
    }
  };

  const makeChangeId = (xs) => {
    return xs[target];
  };

  React.useEffect(() => {
    if (typeof window === "undefined") return;

    const handleScroll = debounce(() => {
      let el;
      const scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;

      container.current.querySelectorAll("section").forEach((node) => {
        try {
          const link = document.querySelector(
            `[href="#${node.getAttribute("id")}"]`
          );

          if (
            !el &&
            node.getBoundingClientRect().top + window.scrollY > scrollTop - 10
          ) {
            el = true;
            link.classList.add("focus");
            document.getElementById("toc").scroll({
              top: link.offsetTop,
              behavior: "smooth",
            });
          } else {
            link.classList.remove("focus");
          }
        } catch (e) {
          // noop
        }
      });
    }, 15);

    document.addEventListener("scroll", handleScroll, {});

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  React.useEffect(() => {
    setOpen(matches);
  }, [matches]);

  return (
    <Overflow>
      <Wrapper open={open}>
        <StyleSheet />
        <SEO title={name} />
        <Header>
          <Button
            aria-controls="toc"
            aria-label="toggle-toc"
            open={open}
            onClick={toggle}
          >
            <Icon />
          </Button>
          <Link to="/">
            <Logo src="/colourLogo.png" alt="Infoware Logo" />
          </Link>
        </Header>
        <Main>
          <h1>{name}</h1>
          {children}
          <article ref={container}>
            {map(entries, (item) => {
              const id = makeChangeId(item);

              return (
                <Section id={id} key={id}>
                  {target === "version" ? (
                    <React.Fragment>
                      <Version>v{item.version}</Version>
                      {item.date && <Date>{item.date}</Date>}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Version>{item.date}</Version>
                      {item.version && <Date>v{item.version}</Date>}
                    </React.Fragment>
                  )}

                  <RichText body={item.notes} />
                  {item.url ? (
                    <DownloadButtonDiv>
                      <ButtonExternalLink small fill="success" href={item.url}>
                        &#129095; Download
                      </ButtonExternalLink>
                    </DownloadButtonDiv>
                  ) : null}
                </Section>
              );
            })}
          </article>
        </Main>
        <Footer />
      </Wrapper>
      <TableOfContents id="toc" open={open}>
        <h3>Table of contents</h3>
        <ul>
          {map(entries, (item) => {
            const id = makeChangeId(item);

            return (
              <li key={`link-${id}`}>
                <a href={`#${id}`} onClick={handleLink(id)}>
                  {id}
                </a>
              </li>
            );
          })}
        </ul>
      </TableOfContents>
    </Overflow>
  );
}
