import React from "react";
import { graphql } from "gatsby";
import TableOfContents from "../components/TableOfContents";
import { get } from "lodash";

const WordChangeLog = (props) => (
  <TableOfContents
    name="Word LX™ Changelog"
    entries={get(props, "data.allContentfulChangelog.nodes")}
    target="version"
  />
);

export const query = graphql`
  {
    allContentfulChangelog(
      filter: { product: { eq: "WordLX" } }
      sort: { order: DESC, fields: version }
    ) {
      nodes {
        date
        version
        notes {
          childContentfulRichText {
            html
          }
        }
      }
    }
  }
`;

export default WordChangeLog;
